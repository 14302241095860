import React, { useEffect } from "react"
import LayoutComponent from "../components/LayoutComponent/LayoutComponent"
import MenuComponent from "../components/MenuComponent/MenuComponent"
import SEO from "../components/seo"
import "../styles/global.scss"
import { unloadRecaptcha } from "../utils/Captcha"

const Presse = () => {
  useEffect(() => {
    unloadRecaptcha()
  }, [])

  return (
    <LayoutComponent languageSwitchSubpage="presse">
      <SEO title="Press" />
      <MenuComponent selected={0} isEnglish />
      <h2 className="underline-headline a-little-bit-of-margin-top">Press</h2>

      <ul className="link-list-wrapper">
        <li className="link-list-header">Presseartikel Downloads</li>
        <li>
          <a
            href="/presse/BietZt_Lokales_Ab heute wird daheim gerempelt_28.9.2013 .pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            BietZt Lokales Ab heute wird daheim gerempelt (from 28.9.2013)
          </a>
        </li>
        <li>
          <a
            href="/presse/LKZ_Lokalsport_Blaue Flecken gehoeren dazu_28.05.2013.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            LKZ Lokalsport Blaue Flecken gehoeren dazu (from 28.05.2013)
          </a>
        </li>
        <li>
          <a
            href="/presse/LKZ_Lokalsport_Ein Sport fuer harte Frauen_ 28.05.2013.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            LKZ Lokalsport Ein Sport fuer harte Frauen (from 28.05.2013)
          </a>
        </li>
        <li>
          <a
            href="/presse/LKZ_Lokalsport_Mehr als ein Modephaenomen_04.07.2013.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            LKZ Lokalsport Mehr als ein Modephaenomen (from 04.07.2013)
          </a>
        </li>
        <li>
          <a
            href="/presse/MarbZ_Sport_Hella Wahnsinn auf acht Rollen_20.06.2013.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            MarbZ Sport Hella Wahnsinn auf acht Rollen (from 20.06.2013)
          </a>
        </li>
        <li>
          <a
            href="/presse/StZ_Lokal LB_Nichts fuer Zicken und Prinzessinnen_16.09.2013.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            StZ Lokal LB Nichts fuer Zicken und Prinzessinnen (from 16.09.2013)
          </a>
        </li>
        <li>
          <a
            href="/presse/BWFTDA apprentice release.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            WFTDA apprentice release
          </a>
        </li>

        <li className="link-list-header">Sonstige Media</li>
        <li>
          <a
            href="/presse/radio_interview_stoehrfunk.mp3"
            target="_blank"
            rel="noreferrer noopener"
          >
            Interview
          </a>
          <p>
            Radio SF hat angerufen! Das komplette Interview gibts hier: ROLL FOR
            YOUR LIFE
          </p>
        </li>
      </ul>
    </LayoutComponent>
  )
}

export default Presse
